import React from "react"
import ArticleCard from "./article-card"
import CommonPageHeader from "@components/common/CommonPageHeader/CommonPageHeader";

const ArticlesGrid = (params) => {
    const {articles} = params;

    return (

        <>
            <CommonPageHeader title="Blog" subtitle="Blog" />
            <section className="blog-area pt-100 pb-60">
                <div className="container">
                    <div className="row">

                        {articles.map((article) => (
                            <ArticleCard column="4" article={article.node} />
                        ))}


                    </div>

                    {/*<BlogPaginationActiveTwo/>*/}

                </div>
            </section>

        </>


    )
}

export default ArticlesGrid;