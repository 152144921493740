import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Layout from "@components/layout";
import {graphql} from "gatsby";
import ArticlesGrid from "@components/articles/articles-grid";
import Pager from "@components/pager/pager";

const ArticlesPage: React.FC<PageProps> = (params: any) => {

    return (
        <Layout>
            <main>
                <ArticlesGrid articles={params.data.allStrapiArticle.edges} />
                <Pager pageContext={params.pageContext} />
            </main>
        </Layout>
    )
}

export default ArticlesPage

export const pageQuery = graphql`
    query ($skip: Int!, $limit: Int!){
        allStrapiArticle(
            limit: $limit
            sort: {order: DESC, fields: publishedAt} 
            skip: $skip
        ) {
            edges {
                node {
                    ...ArticleCard
                }
            }
        }
    }
    
`

export const Head: HeadFC = () => <title>Blog - Fizjoproject Kiekrz</title>
