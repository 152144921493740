import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {dateFormat, getArticleUrl, textPreview} from "@utils/utils";

const ArticleCard = ({ article, column }) => {

    const articleURL = getArticleUrl(article);

    const author = `${article.createdBy.data.attributes.firstname} ${article.createdBy.data.attributes.lastname}`;
    const createdAt = dateFormat(article.publishedAt);
    return (
            <>
                <div className={`col-lg-${column} col-md-6`}>
                    <article className="postbox post format-image mb-40">
                        <div className="postbox__thumb">
                            <Link to={articleURL}>
                                <GatsbyImage
                                    image={getImage(article.cover?.localFile)}
                                    alt={article.cover?.alternativeText}
                                />
                            </Link>
                        </div>
                        <div className="postbox__text p-30">
                            <div className="post-meta mb-15">
                                <span><i className="far fa-calendar-check"></i>{ createdAt }</span>
                                <span><Link to="/blogDetails"><i className="far fa-user"></i>{author}</Link></span>
                            </div>
                            <h3 className="blog-title blog-title-sm">
                                <Link to={articleURL}>{article.title}</Link>
                            </h3>
                            <div className="post-text">
                                <p>{ textPreview(article.description, 20) }</p>
                            </div>
                            <div className="read-more">
                                <Link to={articleURL} className="read-more">czytaj więcej <i className="fas fa-long-arrow-alt-right"></i></Link>
                            </div>
                        </div>
                    </article>
                </div>
            </>
    )
}

export const query = graphql`
    fragment ArticleCard on STRAPI_ARTICLE {
        strapi_id
        slug
        title
        description
        publishedAt
        cover {
            localFile {
                childImageSharp {
                    gatsbyImageData(aspectRatio: 1.77)
                }
            }
        }
        createdBy {
            data {
                attributes {
                    firstname
                    lastname
                }
            }
        }
    }
`

export default ArticleCard;