import React, {useEffect, useState} from 'react';
import { Link } from 'gatsby';

const Pager = ({ pageContext }) => {
    const [pages, setPages] = useState([]);
    const { previousPagePath, nextPagePath, numberOfPages, humanPageNumber, pageNumber } = pageContext;

    useEffect(() => {
        const pageState = [];
        for (let i = pageNumber - 2; i < pageNumber + 4; i++) {
            if (i >= 0 && i < numberOfPages) {
                pageState.push(i);
            }
        }

        setPages(pageState);
    }, []);

    const getLink = (pageNum: number): string => {
        return pageNum > 0 ? "/articles/" + (pageNum + 1) : "/articles"
    }

    return (

        <>
            <div className="basic-pagination basic-pagination-2 text-center pb-60">
                <ul>
                    {
                        previousPagePath && (
                            <li>
                                <Link className="readStoryLink" to={previousPagePath}>
                                    <i className="fa fad fa-angle-double-left"></i>
                                </Link>
                            </li>
                        )
                    }

                    { pages.map((page) => (
                        <li className={page === pageNumber ? "active" : ""} key={page}>
                            <Link to={getLink(page)}>{page + 1}</Link>
                        </li>
                    )) }

                    {
                        nextPagePath && (
                            <li>
                                <Link className="readStoryLink" to={nextPagePath}>
                                    <i className="fa fad fa-angle-double-right"></i>
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </div>
        </>
    );
};

export default Pager;
